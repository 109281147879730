import { createAction, props } from '@ngrx/store';
export const SOCKET_JOIN_ROOM = '[SOCKET] join room';
export const SOCKET_LEAVE_ROOM = '[SOCKET] leave room';
export const SOCKET_JOIN_ROOM_SUCCESS = '[SOCKET] join room success';
export const SOCKET_LEAVE_ROOM_SUCCESS = '[SOCKET] leave room success';
export const SOCKET_RECONNECT = '[SOCKET] reconnect';
export const SOCKET_RECONNECT_SUCCESS = '[SOCKET] reconnect success';
export const SOCKET_INITIALIZE_CONNECTION = '[SOCKET] initialize connection';


export const socketJoinRoom = createAction(
  SOCKET_JOIN_ROOM,
  props<{roomName: string, roomId: string }>()
);

export const socketLeaveRoom = createAction(
  SOCKET_LEAVE_ROOM,
  props<{ roomName: string, roomId: string }>()
);

export const socketJoinRoomSuccess = createAction(
  SOCKET_JOIN_ROOM_SUCCESS,
  props<{ roomName: string, roomId: string }>()
);

export const socketLeaveRoomSuccess = createAction(
  SOCKET_LEAVE_ROOM_SUCCESS,
  props<{ roomName: string, roomId: string }>()
);

export const socketReconnect = createAction(
  SOCKET_RECONNECT,
);

export const socketReconnectSuccess = createAction(
  SOCKET_RECONNECT_SUCCESS
);
export const socketInitializeConnection = createAction(
  SOCKET_INITIALIZE_CONNECTION,
  (platformName: string, ipSessionKey: string) => ({ platformName, ipSessionKey })
);
